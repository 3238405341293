






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class FieldToImageGuesser extends Vue {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({}) field!: any;
}
