


































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import LoadResourceMixin from '@/mixins/LoadResourceMixin';
import ViewFields from '@/components/View/ViewFields.vue';

@Component({
  components: { ViewFields },
})
export default class ViewResource extends mixins(LoadResourceMixin) {
  get editable(): boolean {
    if (
      this.resource?.title === 'Template'
      && !this.$store.state.auth.user.roles.includes('ROLE_SUPER_ADMIN')
    ) {
      return false;
    }

    if (this.resource != null && this.resource['@context'] != null) {
      const resourceName = this.resource['@context'].split('/').reverse()[0];

      return !!(this.$options?.components && this.$options.components[`${resourceName}EditForm`]);
    }

    return false;
  }

  goBack() {
    if (window) {
      window.history.back();
    }
  }
}
